import styled from 'styled-components';
// const breakpoints = ["405px", "52em", "64em", "80em"];
// const space = [0, 4, 8, 16, 32, 64, 128, 160, 256, 512];

import {
  space,
  color,
  layout,
  background,
  position,
  border,
  flexbox,
  typography,
} from 'styled-system';

const theme = {
  colors:{
    darkGray: "#000000ad",
  },
  letterSpacings:{
    normal: 0,
    slight: '.05em',
    title: ".1em",
    loose: ".25em"
  },
  breakpoints:["405px", "52em", "64em", "80em"],
  space:[0, 4, 8, 16, 32, 64, 128, 160, 256, 512],
  maxWidths:["40em", "52em", "64em", "80em", "96em", "104em"],
}

export const Profile = styled.img`
  ${layout}
  ${space}
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  flex-shrink: 0;
`;

export const Box = styled.div`
  box-sizing: border-box;
  ${space}
  ${color}
  ${layout}
  ${background}
  ${position}
  ${border}
`;

Box.displayName = 'Box';


export const Flex = styled(Box)`
  display: flex;
  ${flexbox}
`

Flex.displayName = 'Flex'

export const Text = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${p => (p.wrap ? 'wrap' : 'nowrap')};
  color: ${theme.colors.darkGray};
  letter-spacing: ${theme.letterSpacings.slight};

  ${typography}
`

Text.displayName = 'Text'

Text.defaultProps = {
  fontSize: [1, 2],
}

Text.Heading = ({ children, bold, ...props }) => {
  return (
    <Text
      fontSize={[7, 8, 9]}
      fontWeight="bold"
      letterSpacing="slight"
      fontFamily={bold ? 'TTMussels-Bold' : 'TTMussels'}
      {...props}
    >
      {children}
    </Text>
  )
}


Text.Link = styled.a`
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 1;
    color:black;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${p => (p.wrap ? 'wrap' : 'nowrap')};
  color: ${theme.colors.darkGray};
  letter-spacing: ${theme.letterSpacings.slight};
  ${typography}
`

Text.Link.defaultProps = {
  // color: 'link',
  fontSize: 1,
  opacity: 0.5,
}

Text.Label = styled.label`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${p => (p.wrap ? 'wrap' : 'nowrap')};
  color: ${theme.colors.darkGray};
  letter-spacing: ${theme.letterSpacings.slight};
  ${color}
  ${typography}
`

Text.Label.displayName = 'Label'

