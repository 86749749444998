import normalize from 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'
import {Flex,Box,Text,Profile} from './styles'
import Fa from '@fortawesome/fontawesome-free/css/all.css'
import {Link} from 'react-router-dom'
import david from './headshot1.jpg'

function App(){
  return <Flex height='100%' width='100%' justifyContent='center' alignItems='center'>
    <Flex flexDirection='column' justifyContent='center' alignItems='center'>
      <Text.Link href='https://github.com/daywiss' target='_blank' rel="noreferrer">
        <Profile m={3} width={256} height={256} src={david} alt="David Adams Headshot" title="David Adams Github"/>
      </Text.Link>
      <Text> David Adams </Text>
      <Text>Fullstack Javascript Developer</Text>
      <Flex>
        <Box p={2}>
          <Text.Link href='files/DavidAdamsResume2020.pdf' target='_blank' title="Resume" rel="noreferrer">
            <i className="fas fa-file-pdf fa-3x"></i>
          </Text.Link>
        </Box>
        <Box p={2}>
          <Text.Link href='https://github.com/daywiss' target='_blank' title="Github" rel="noreferrer">
            <i className="fab fa-github fa-3x"></i>
          </Text.Link>
        </Box>
        <Box p={2}>
          <Text.Link href='https://www.linkedin.com/in/david-p-adams/' target='_blank' title="LinkedIn" rel="noreferrer">
            <i className="fab fa-linkedin fa-3x"></i>
          </Text.Link>
        </Box>
        <Box p={2}>
          <Text.Link href='https://forms.gle/ZnDe25eyctRvbeFS7' target='_blank' title="Contact" rel="noreferrer">
            <i className="fas fa-envelope-open-text fa-3x"></i>
          </Text.Link>
        </Box>
      </Flex>
    </Flex>
  </Flex>
}

ReactDOM.render(
  <App/>,
  document.getElementById('app')
)
